.swiper-wrapper {
    align-items: top;
}
.swiper-button-prev, .swiper-button-next {
    color: #008C81 !important;
}
.swiper-button-disabled {
    /* color: #00A68C !important; */
    display: none;
}
.swiper-pagination-bullet-active {
    background: #008C81 !important;
}
.swiper-pagination {
    bottom: 0 !important;
}

@media screen and (max-width: 768px) {
    /* .swiper-button-next {
        margin-right: -10px;
    }
    .swiper-button-prev {
        margin-left: -10px;
    } */
    .swiper-button-prev, .swiper-button-next {
        color: #008C81 !important;
        position: fixed;
        top: calc(100vh - 50%) !important;
        bottom: calc(100vh - 50%) !important;
    }
    .swiper-pagination {
        display: none;
    }
}